import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewCountryRisk from '@app/src/components/Drawer/Views/DrawerViewCountryRisk'
import { getMapPieces } from '@app/src/pages/Dashboards/SourcingDashboard/CountryRisks/CountryRiskMap'
import { Provider, ProviderLinkStatus, RiskTableView } from '@app/src/types/organizations'
import { Country, Risk, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { distinctBy } from '@app/src/utils/helpersTs'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { MapChart } from 'echarts/charts'
import {
  GeoComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { GeoJSONSourceInput } from 'echarts/types/src/coord/geo/geoTypes'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { UseQueryResult, useQuery } from 'react-query'

const useWorldMap = (): UseQueryResult<GeoJSONSourceInput> =>
  useQuery('map', async () => await fetch('/world-map-light.json').then(response => response.text()))

export enum RiskLocationEnum {
  Headquarter = 'headquarter',
  OwnOperations = 'ownOperations',
  Subsupplier = 'subsupplier',
}

export type ProviderRisk = {
  name: string
  provider: Provider
  countryName: string
  countryCode: string
  country: Country
  value?: number
  riskType: RiskLocationEnum
  riskStatus?: RiskStatus
  risk?: Risk
  count?: number
}

export type ExposedCountry = Omit<ProviderRisk, 'provider' | 'risk' | 'riskType'>

type ExposedCountryRiskMapProps = {
  activeRisk?: RiskType
  selectedRiskId: number
  riskScreening: RiskTableView[]
  riskLevelFilter?: Array<string>
  height?: number
  getEChartsRef?: (eChartsRef: React.MutableRefObject<null | ReactEChartsCore>) => void
  riskLocationsTypes?: Array<RiskLocationEnum>
  onCountryClick?: (country: Country, riskStatuses: RiskStatus[], providerRisks: ProviderRisk[]) => void
}

const createRiskObject = (
  parentObject: Provider,
  country: Country,
  riskType: RiskLocationEnum,
  risk?: Risk,
): ProviderRisk => ({
  name: country.countryCode.toUpperCase(),
  provider: parentObject,
  countryName: country.name,
  countryCode: country.countryCode,
  country: country,
  value: risk?.value,
  riskType,
  riskStatus: risk?.status,
  risk,
})

const ExposedCountryRiskMap: React.FC<ExposedCountryRiskMapProps> = ({
  activeRisk,
  selectedRiskId,
  riskScreening,
  riskLevelFilter,
  height = 500,
  getEChartsRef,
  riskLocationsTypes = [RiskLocationEnum.Headquarter, RiskLocationEnum.OwnOperations, RiskLocationEnum.Subsupplier],
  onCountryClick,
}) => {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    SVGRenderer,
    LegendComponent,
    GeoComponent,
    VisualMapComponent,
    MapChart,
  ])
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { data: worldMap } = useWorldMap()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { openDrawer } = useDrawer()
  const { typography } = useTheme()

  if (eChartsRef && getEChartsRef) getEChartsRef(eChartsRef)

  const providerRisks: ProviderRisk[] = useMemo(
    () =>
      riskScreening
        .flatMap(({ parentObject }) => {
          const isConnected = parentObject.linkStatus === ProviderLinkStatus.Connected
          const risks = []

          if (riskLocationsTypes.includes(RiskLocationEnum.Headquarter) && !isConnected && parentObject?.country) {
            const risk = parentObject.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
            risks.push(createRiskObject(parentObject, parentObject.country, RiskLocationEnum.Headquarter, risk))
          }

          if (
            riskLocationsTypes.includes(RiskLocationEnum.OwnOperations) &&
            parentObject?.organization?.organizationsCountriesExposure?.length
          ) {
            parentObject.organization?.organizationsCountriesExposure
              .filter(exposure => exposure.variant === 'Operation')
              .forEach(operation => {
                const risk = operation.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
                risks.push(createRiskObject(parentObject, operation.country, RiskLocationEnum.OwnOperations, risk))
              })
          }

          if (
            riskLocationsTypes.includes(RiskLocationEnum.Subsupplier) &&
            parentObject?.organization?.organizationsCountriesExposure?.length
          ) {
            parentObject.organization?.organizationsCountriesExposure
              .filter(exposure => exposure.variant === 'Subsupplier')
              .forEach(subSupplier => {
                const risk = subSupplier.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
                risks.push(createRiskObject(parentObject, subSupplier.country, RiskLocationEnum.Subsupplier, risk))
              })
          }

          if (riskLocationsTypes.includes(RiskLocationEnum.Headquarter) && parentObject?.organization?.country) {
            const risk = parentObject.organization?.country?.risks?.find(risk => risk.riskTypeId === selectedRiskId)
            risks.push(
              createRiskObject(parentObject, parentObject.organization?.country, RiskLocationEnum.Headquarter, risk),
            )
          }

          return risks
        })
        .flat()
        .filter(ec => riskLevelFilter?.includes(ec.riskStatus ?? '')),
    [riskScreening, selectedRiskId, riskLevelFilter, riskLocationsTypes],
  )

  const exposedCountries = useMemo<ExposedCountry[]>(
    () =>
      providerRisks
        .filter((item, index, self) => index === self.findIndex(t => t.name === item.name))
        .map(providerRisk => ({
          name: providerRisk.name,
          countryName: providerRisk.countryName,
          countryCode: providerRisk.countryCode,
          country: providerRisk.country,
          value: providerRisk.value,
          count: providerRisks.filter(x => x.name === providerRisk.name).length,
          riskStatus: providerRisk.riskStatus,
        })),
    [providerRisks],
  )

  // @ts-expect-error Suppressed to make the scatter series label formatter params type work
  const options: EChartsOption = useMemo(() => {
    return {
      geo: {
        nameProperty: 'iso_a2',
        map: 'worldMap',
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: palette.grey[200],
            borderColor: 'rgba(255, 255, 255, 0)',
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        roam: true,
        zoom: 1,
        scaleLimit: {
          min: 1,
          max: 18,
        },
        itemStyle: {
          borderColor: palette.common.white,
          borderWidth: 1,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0)',
        },
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: formattingItem => {
          const item = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
          const data = exposedCountries[item.dataIndex]

          if (!data?.countryName) return ''
          return `${data.countryName} - ${formatMessage({ id: `schemas.risk.${data.riskStatus ?? 'notApplicable'}` })}`
        },
      },
      visualMap: {
        type: 'piecewise',
        min: 0,
        max: 100,
        pieces: getMapPieces(palette, activeRisk),
        inverse: true,
        text: [
          formatMessage({ id: 'dashboard.riskByCountryMapLow' }),
          formatMessage({ id: 'dashboard.riskByCountryMapHigh' }),
        ],
        textStyle: {
          color: palette.text.primary,
        },
        calculable: true,
        seriesIndex: 0,
      },
      series: [
        {
          name: 'risk1',
          type: 'map',
          map: 'worldMap',
          geoIndex: 0,
          emphasis: {
            label: {
              show: true,
            },
          },
          itemStyle: {
            color: palette.grey[300],
          },
          select: {
            disabled: true,
          },
          data: exposedCountries,
        },
        {
          name: 'providerCountLabels',
          type: 'scatter',
          coordinateSystem: 'geo',
          animation: false,
          silent: true,
          data: exposedCountries.map(x => ({
            count: x.count,
            value: [x.country.longitude, x.country.latitude],
          })),
          symbolSize: 0,
          label: {
            show: true,
            opacity: 1,
            padding: 3,
            borderRadius: 10,
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,

            // Shadow is used for achieving some extra top padding
            shadowColor: palette.primary.main,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: -2,

            formatter: (params: { data: { count: number } }) => {
              return ` ${params.data.count} `
            },
          },
        },
      ],
    }
  }, [activeRisk, exposedCountries, palette, formatMessage])

  const events = useMemo(
    () => ({
      click: ({ data }: { data: ExposedCountry }) => {
        if (data) {
          const countryProviderRisks = providerRisks.filter(ec => ec.name === data.name)

          if (onCountryClick) {
            const riskStatuses = distinctBy(
              countryProviderRisks.map(x => x.riskStatus),
              riskStatus => riskStatus,
            ).filter(Boolean) as RiskStatus[]
            onCountryClick(data.country, riskStatuses, countryProviderRisks)
          } else {
            openDrawer(
              <DrawerViewCountryRisk
                title={data.countryName}
                subTitle={`${formatMessage({
                  id: `schemas.risk.riskIndexes.${activeRisk?.source}.label`,
                })} ${formatMessage(
                  { id: 'transparencyOverview.valueWithDotSeparatorBefore' },
                  { value: formatMessage({ id: `schemas.risk.${data.riskStatus}` }) },
                )}`}
                providerRisks={countryProviderRisks}
              />,
            )
          }
        }
      },
    }),
    [openDrawer, formatMessage, providerRisks, activeRisk],
  )

  if (!worldMap) return null

  echarts.registerMap('worldMap', worldMap)

  return (
    <ReactEChartsCore
      option={options}
      echarts={echarts}
      style={{ width: '100%', height: height || 500 }}
      onEvents={events}
      ref={eChartsRef}
    />
  )
}

export default ExposedCountryRiskMap

import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodField'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ReportFlagRule, ReportFlagType } from '@app/src/types/flags'
import { AccountType, RequestTags, ResourceTypes } from '@app/src/wf-constants'
import { ButtonProps } from '@mui/material'
import { FinalRiskRating } from './categories'
import { GenericOrganization, Investee, Organization, Provider, Supplier } from './organizations'

export interface RelatedResource {
  type: string
  url: string
}

export interface ResourceType {
  id: number
  image?: Image
  name: string
  linked?: boolean
  websiteAddress?: string
}

export interface ResourceData {
  label: string | JSX.Element
  value: string | JSX.Element
}

export interface FilterColumn {
  name: string
  id: number
  type: string
  filterTarget: string
}

export interface ActionOptions {
  type: string
  label: string | JSX.Element
  callback?: () => void
  disabled?: boolean
  variant?: ButtonProps['variant']
}

export interface ResourceActions {
  icon?: string
  callback?: () => void
  options?: Array<ActionOptions>
}

export interface Image {
  name: string
  url: string
}

export interface RiskSource {
  riskTypeId: number
  headquarter?: Risk
  operation?: AggregatedCountryRisk
  subsupplier?: AggregatedCountryRisk
}

export interface AggregatedCountryRisk {
  countryId: number
  countryName: string
  highestRisk: Risk
}

export enum RiskIndexSource {
  WgiHumanRights = 'WgiHumanRights',
  TransparencyInternationalCorruption = 'TransparencyInternationalCorruption',
  WriWaterRisk = 'WriWaterRisk',
  MinderooFoundationModernSlavery = 'MinderooFoundationModernSlavery',
  ItucLaborRights = 'ItucLaborRights',
  WwfBiodiversity = 'WwfBiodiversity',
  WwfSoilCondition = 'WwfSoilCondition',
  SaveTheChildrenChildLabor = 'SaveTheChildrenChildLabor',
  SaveTheChildrenEndOfChildHood = 'SaveTheChildrenEndOfChildHood',
  UndpHumanDevelopment = 'UndpHumanDevelopment',
  WorldfavorDeforestation = 'WorldfavorDeforestation',
  WwfTreeCoverLoss = 'WwfTreeCoverLoss',
  WwfAirCondition = 'WwfAirCondition',
  InformClimateChange = 'InformClimateChange',
}

export enum SustainabilityArea {
  Environment = 'Environment',
  Social = 'Social',
  Governance = 'Governance',
}

export enum RiskIndexType {
  Country = 'Country',
}

export interface OrganizationRiskSetting {
  isActivated: boolean
  riskType: RiskType
}

export interface RiskType {
  id: number
  name: string
  description: string
  source: RiskIndexSource
  sustainabilityArea: SustainabilityArea
  riskIndexType: RiskIndexType
  extremeLimit: number
  highLimit: number
  mediumLimit: number
  reviewedAt: string
}

export interface Risk {
  id: number
  riskType: RiskType
  riskTypeId: number
  targetObjectId: number
  targetObjectType: string
  value: number
  status: RiskStatus
}

export enum RiskStatus {
  Extreme = 'FExtreme',
  High = 'EHigh',
  Medium = 'DMedium',
  Low = 'CLow',
}

export interface Country {
  id: number
  countryCode: string
  name: string
  latitude: number
  longitude: number
  image: Image
  risks: Risk[]
}

export interface User {
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  position: string
  auth0UserId: string
  name: string
  id: number
  createdAt: string
  image?: Image
  type: string
  role: string
  isPublicContact: boolean
}

export interface Contact extends ResourceType {
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  role: string
  user: User
  givenName: string
  familyName: string
  email: string
  userId: number
  name: string
  creatorOrganization: GenericOrganization
  type: string
}

export interface ReferralContact extends ResourceType {
  name: string
  firstName: string
  lastName: string
  email: string
  referralEmailHistory: ReferralEmailHistory[]
  creatorOrganization?: Organization
  referralCode: string
}

export interface ReferralEmailHistory {
  id: number
  referralContactId: number
  createdAt: string
  providerId: number
}

export interface Invitation extends ResourceType {
  id: number
  creatorOrganization: GenericOrganization
  creatorOrganizationId: number
  creatorUserId: number
  creatorUser: User
  email: string
  userId: number
  user: User
  role: string
  solutionType: string
  invitationStatus: string
  createdAt: string
  status: string
  type: string
}

export interface Collection {
  value: string
  displayText: string
}

export interface UploadReference {
  originalFileName: string
  id: number
  sourceObjectId: number
  sourceObjectType: string
}

export interface FileAccess {
  fileId: number
  accessOrganizationId: number
  creatorOrganizationId: number
  createdAt: string
  creatorUserId: number
  creatorUser: User
}

export interface File {
  id: number
  guid: string
  originalFileName: string
  sourceObjectId: number
  sourceObjectType: string
  accesses?: FileAccess[]
  createdAt: string
  deletedAt: string
  creatorUserId: number
  creatorUser?: User
  creatorOrganizationId: number
  creatorOrganization?: GenericOrganization
}

export interface ProviderComment {
  id: number
  objectInstanceId: number
  text: string
  createdAt: string
  creatorOrganizationId: number
  creatorOrganization?: GenericOrganization
  creatorUserId: number
  creatorUser?: User
}

export interface RequestItem {
  id: number
  questionId: number
  requestSectionId: number
  description: string
  guidance?: string
  order: number
  questionTypeId: number
  questionType: {
    name: string
  }
  template?: Question
  allowMultiChoice?: boolean
  isRequired: boolean
  unit: Unit
  unitId: number
  requestCollection: {
    id: number
    items: Collection[]
  }
  requestCollectionId: number
  section: {
    title: string
    requestId: number
  }
}

export interface RequestCollectionPicker {
  id: number
  name: string
  isGlobal: boolean
  permittedOptions: string[]
}

export interface RequestSection {
  id: number
  title: string
  description: string
  order: number
  items: RequestItem[]
}

export enum ReportTemplateState {
  None = 'None',
  Recommended = 'Recommended',
  Reported = 'Reported',
}

export enum RequestStatus {
  NotSet = 'NotSet',
  Sent = 'Sent',
  ResponseSubmitted = 'ResponseSubmitted',
  ResponseCorrectionNeeded = 'ResponseCorrectionNeeded',
  ResponseApproved = 'ResponseApproved',
}

export enum InquiryStatus {
  NotSet = 'NotSet',
  Requested = 'Requested',
  Submitted = 'Submitted',
  Approved = 'Approved',
  CorrectionNeeded = 'CorrectionNeeded',
  Rejected = 'Rejected',
}

export interface Inquiry {
  id: number
  createdAt: string
  template: QuestionnaireTemplate
  questionnaireTemplateId: number
  requestId?: number
  request?: Request
  status: InquiryStatus
  providerId: number
  provider: Provider
  deadline: string
  activatedAt: string
  isActivated: boolean
  periodStartsAt: string
  periodEndsAt: string
  solutionType: string
  creatorOrganization: GenericOrganization
  creatorOrganizationId: number
  periodType: string
  periodName: string
}

export interface Request {
  id: number
  tags: Array<RequestTags>
  type: typeof ResourceTypes.Request
  createdAt: string
  creatorOrganization: GenericOrganization
  creatorOrganizationId?: number
  responderOrganization: GenericOrganization
  responderId: number
  questionnaireTemplateId: number
  template: QuestionnaireTemplate
  title: string
  requestStatus: RequestStatus
  request: Request
  responseInternalStatus: InternalVerificationStatus
  responseExternalStatus: ExternalVerificationStatus
  periodStartsAt: string
  periodEndsAt: string
  periodName: string
  solutionTypeId: number
  image?: Image
  targetObject: GenericOrganization
  targetAliasObject: GenericOrganization
  targetAliasObjectType: string
  responses?: Response[]
  sections: RequestSection[]
  responderTargetObjectType: string
  responderOrganizationId: number
  description?: string
  isPrimarySubscriber: boolean
  reportTemplateState?: ReportTemplateState
  inquiries?: Inquiry[]
  subscriptions: Subscription[]
}

export enum SolutionType {
  Default = 'Default',
  Investing = 'Investing',
  Sourcing = 'Sourcing',
  Transparency = 'Transparency',
  Management = 'Management',
  Finance = 'Finance',
  EsgReporting = 'Esg Reporting',
}
export interface SharedTemplate {
  creatorOrganizationId: number
  requestTemplateId: number
  organizationId: number
  organization: GenericOrganization
  solutionType: SolutionType
}

export enum QuestionnaireTypeEnum {
  Regular = 'Regular',
  Shared = 'Shared',
}

export interface ReportAccessModel {
  requestId: number
  organizationIds: number[]
  isAllAccess: boolean
}

export enum ExternalVerificationStatus {
  NotSet = 'NotSet',
  Approved = 'Approved',
  CorrectionNeeded = 'CorrectionNeeded',
}

export enum InternalVerificationStatus {
  NotSet = 'NotSet',
  Approved = 'Approved',
  NotApproved = 'NotApproved',
}

export interface Response {
  id: number
  creatorOrganization: GenericOrganization
  creatorOrganizationId: number
  creatorUserId: number
  createdAt: string
  targetRequestId: number
  responderTargetTypeId: number
  submittedAt: string
  draftStatus: ResponseDraftStatus
  externalVerificationStatus: ExternalVerificationStatus
  externalVerificationComment: string
  internalVerificationStatus: InternalVerificationStatus
  internalVerificationComment: string
  responseHash: string
  responderOrganization: GenericOrganization
  responderTarget: GenericOrganization
  responderTargetObject: GenericOrganization
  request: Request
  items: ResponseItem[]
  creatorUser: Contact
  responderTargetType: string
  responderModel: string
  responderObjectId: number
  responderObject: GenericOrganization
  verificationOrganizationId: number
  verifications?: Verification[]
  responseAnalytics?: ResponseAnalytics
}

export interface ResponseAnalytics {
  id: number
  responseId: number
  flagCount: number
}

export interface ResponseWithPreviousCorrectionNeededItem extends Response {
  items: ResponseItemWithPreviousCorrectionNeededItem[]
}

export interface ResponseItem {
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorOrganization: GenericOrganization
  creatorUserId: number
  requestItemId: number
  responseId: number
  receiverTypeId: number
  receiverObjectId: number
  receiverContactId: number
  targetTypeId: number
  isValid: boolean
  externalVerificationStatus: ExternalVerificationStatus
  internalVerificationStatus: InternalVerificationStatus
  externalVerificationComment: string
  internalVerificationComment: string
  verificationComment: string
  verificationUserId: number
  answer: number | string
  isAnswered: boolean
  isCollection: boolean
  selectedOptions: string[]
  request: Request
  response: Response
  requestItem: RequestItem
  responderObject: GenericOrganization
  responderOrganization: GenericOrganization
  responderTarget: GenericOrganization
  responderTargetObject: GenericOrganization
  uploadReferences: UploadReference[]
  responderTargetType: string
  cannotAnswer: boolean
  estimatedAnswerAt: string | null
  expiresAt: string | null
  selected: boolean
  isValueSet: boolean
  flag: ReportFlagType
  flagRule?: ReportFlagRule
  verifications?: VerificationResponseItem[]
  fileScreeningResults?: FileScreeningResult[]
  answerStatus: AnswerStatus
  comment?: string
}

export enum AnswerStatus {
  Answered = 'Answered',
  NotApplicable = 'NotApplicable',
  NotAvailable = 'NotAvailable',
  Other = 'Other',
}

export interface ResponseItemWithPreviousCorrectionNeededItem extends ResponseItem {
  previousCorrectionNeededAnswer: ResponseItem
}

export type BaseVerification = {
  id: number
  createdAt: string
  creatorOrganizationId: number
  providerOrganizationId: number
  creatorOrganization?: GenericOrganization
  creatorUser?: User
  externalVerificationStatus: ExternalVerificationStatus
  externalVerificationComment: string
  internalVerificationComment: string
  internalVerificationStatus: InternalVerificationStatus
  externalVerificationStatusSetAt: string
}

export type VerificationResponseItem = BaseVerification & {
  requestItemId: number
  responseItemId: number
}

export type Verification = BaseVerification & {
  requestId: number
  responseId: number
  creatorUserId: number
}

export interface QuestionnaireTemplate {
  title: string
  description?: string
  id: number
  creatorOrganization: GenericOrganization
  creatorUser: User
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  tags: RequestTags[]
  isPublished?: boolean
  sections?: QuestionnaireTemplateSection[]
  questionnaireTemplateType: QuestionnaireTypeEnum
  sharedTemplates?: SharedTemplate[]
  image?: Image
  assessmentQuestionnaireLink?: AssessmentQuestionnaireLink[]
  isStandardReport: boolean
}

export interface QuestionnaireTemplateSection {
  id: number
  title: string
  description: string
  questions: QuestionInTemplate[]
  questionnaireTemplateId: number
  order: number
}

export interface QuestionInTemplate extends Question {
  isRequired: boolean
  order: number
}

export interface LogIdentity {
  userId: number
  organizationId: number
}

export enum ResponseDraftStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
}

export type AllStatuses =
  | InquiryStatus
  | ResponseDraftStatus
  | ExternalVerificationStatus
  | InternalVerificationStatus
  | RequestStatus

export enum HistoryStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  CorrectionNeeded = 'CorrectionNeeded',
  Approved = 'Approved',
}

export enum MappingRequestStatus {
  DueSoon = 'DueSoon',
  Overdue = 'Overdue',
}

export interface RequestHistory {
  requestId: number
  responseId: number
  status: HistoryStatus
  type: string
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  creatorUser: User
}

export interface Snapshot {
  requestItemId: number
  responseId: number
  answer: [{ value: string; selected: boolean }]
  isValid: boolean
  isAnswered: boolean
  cannotAnswer: boolean
  isCollection: boolean
  externalVerificationStatus: ExternalVerificationStatus
  externalVerificationComment: string
  internalVerificationStatus: Indicator
  internalVerificationComment: string
  externalVerificationStatusSetAt: string
  type: string
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  id: number
}

export interface SolutionOrganization {
  organizationId: number
  organizationName: string
  solutionType: string
}

export interface AuditLog {
  logIdentity: LogIdentity
  serviceType: string
  sourceEventType: string
  solutionType: string
  objectType: string
  objectInstanceId: number
  beforeSnapshot: Snapshot
  afterSnapshot: Snapshot
  id: number
  creatorOrganization: GenericOrganization
  creatorUser: User
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  type: string
}

export interface Subscription {
  id: number
  createdAt: string
  isPrimary: boolean
  creatorOrganization: GenericOrganization
  creatorOrganizationId: number
  request: Request
}

export interface ResourceExplorer {
  requestTemplatesData: CollectionObjectResult<QuestionnaireTemplate> | null
  notifications: CollectionObjectResult<Notification> | null
}

export interface Indicator {
  description: string
  guidance: string
  indicatorType: 'Options' | 'Number'
  unitId: number
  unit: Unit
  section: FrameworkSection
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  type: string
  frameworkOptionCollection: FrameworkOptionCollection
}

export interface FrameworkSection {
  title: string
  description: string
  guidance: string
  framework: Framework
  items: Indicator[]
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  type: string
}

export interface Framework {
  title: string
  description: string
  sections: FrameworkSection[]
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  type: string
}

export interface FrameworkOptionCollection {
  createdAt: string
  id: number
  type: string
  values: string[]
}

export interface DataPoint {
  periodStartsAt: string
  periodEndsAt: string
  periodName: string
  evidence: string
  solutionType: string
  indicatorId: number
  indicator: Indicator
  targetObjectId: number
  targetObjectType: string
  targetObject: GenericOrganization
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorUserId: number
  type: string
  value: string | number
}

export interface Unit {
  baseUnitFactor: number | null
  id: number
  name: string
  quantityType: string | null
  symbol: string | null
  baseUnitId: number | null
}

export interface CollectionObjectResult<T> {
  items: T[]
  status: number
  totalResult: number
  pagesCount: number
  itemsPerPage: number
  pageNumber: number
}

export interface ReferralScheduledRequests {
  templateId: number
  periodType: string
  periodStartsAt: string
  periodEndsAt: string
  dueAt: string | null
}

export interface Referral {
  code: string
  creatorOrganizationId: number
  creatorUserId: number
  expiresAt: string
  id: number
  solutionType: string
  scheduledRequests?: ReferralScheduledRequests[]
}

export interface ReferralLink {
  organizationId: number
  code: string
}

export interface AssignAccessorModel {
  requestId: number
  isAllAccess: boolean
  accessorOrganizationIds: number[]
}

export type CurrentResource = Investee | Contact | Supplier | null

export interface SendRequestResult {
  successCount: number
  duplicateCount: number
}

export interface PeriodTemplateCombination {
  templateId: number
  templateTitle: string
  periodName: string
}

export enum GoalDirectionType {
  Above = 'Above',
  Below = 'Below',
  Exact = 'Exact',
}

export interface GoalTracker {
  id: number
  questionId: number
  question: Question
  targetReference: string
  description: string
  goalValue: number
  title: string
  filterCollections: FilterGroup[]
  unitId: number
  goalValueUnit: Unit
  questionnaireTemplateId: number
  goalDirection: GoalDirectionType
}

export interface QuestionnaireStatistics {
  totalQuestionnaires: number
  totalRequests: number
  reportedRequests: number
  percentResponseRate: number
  companiesEngaged: number
}

export interface QuestionnaireRequestProgressStatistics {
  templateId: number
  numberOfRespondedRequests: number
  totalNumberOfRequestsSent: number
  completed: boolean
  templateTitle: string
}

export interface QuestionnaireCompanyProgressStatistics {
  providerId: number
  numberOfRespondedRequests: number
  totalNumberOfRequestsSent: number
  completed: boolean
  responderCompanyName: string
  responderImageUrl: string
  label: string
}

export interface ReportCreateModel {
  periodStartsAt: string
  periodEndsAt: string
  templateId: number
  inquiryId?: number
}

export interface InquiriesByTemplate {
  templateId: number
  templateTitle: string
  templateImageUrl?: string
  requestId?: number
  inquiryInfos: InquiryInfo[]
  periodStartsAt: string
  periodEndsAt: string
  closestDeadline?: string
}

interface InquiryInfo {
  accessorOrganizationId: number
  accessorName: string
  accessorImageUrl?: string
  inquiryId: number
}

export interface AssignSolution {
  organizationId: number
  accountType: AccountType
  isPayingAccessor: boolean
}

export interface RiskPrioritizationSummary {
  riskPrioritization: ProviderRisk
  hqCountry?: Country
}

enum ProviderRiskAdditionalStatuses {
  NotApplicable = 'ANotApplicable',
  Pending = 'BPending',
}

export type ProviderRiskStatusType = RiskStatus | ProviderRiskAdditionalStatuses
export const ProviderRiskStatus = { ...RiskStatus, ...ProviderRiskAdditionalStatuses }
export interface ProviderRisk {
  riskStatus: ProviderRiskStatusType
  providerId: number
  provider: Provider
  periodName: string
  periodStartsAt: string
  periodEndsAt: string
  periodType: Periods
  totalEnvironmentResponses: number
  environmentFlagCount: number
  environmentRiskStatus: ProviderRiskStatusType
  socialFlagCount: number
  socialRiskStatus: ProviderRiskStatusType
  totalSocialResponses: number
  governanceFlagCount: number
  totalGovernanceResponses: number
  governanceRiskStatus: ProviderRiskStatusType
  customFlagCount: number
  customRiskStatus: ProviderRiskStatusType
  finalRisk: FinalRiskRating
}

export interface FileScreeningResult {
  checkName: string
  result: ScreeningResult
  value?: string
}

export enum ScreeningResult {
  Passed = 'Passed',
  Failed = 'Failed',
  Error = 'Error',
}

export interface ParentObject {
  id: number
  title: string
  questionType: string
  unit: Unit
}

export interface PeriodDetails {
  periodName: string
  answer?: number | string
  cannotAnswer?: boolean
  collectionResponse?: string
  isMultiSelectOptionQuestion: boolean
}

export interface ProviderProgressView {
  id: number
  templateId: number
  requestId: number
  responseId: number
  parentObject: ParentObject
  periodDetails: PeriodDetails[]
}

export interface SuggestedResponsePayload {
  organizationInfo: {
    id: number
    name: string
  }
  message: string
}

export type RequestItemPayload = {
  organizationInfo: {
    id: number
    name: string
  }
  requestItem: RequestItem
}

export interface Question {
  id: number
  creatorOrganizationId: number
  questionText: string
  guidance: string
  kpiName: string
  accessorGuidance: string
  categorizations: QuestionCategorization[]
  reportFlagRules?: ReportFlagRule[]
  questionTypeId: number
  allowMultiChoice?: boolean
  unitId?: number
  unit?: Unit
  questionType: {
    name: string
  }
  requestCollectionId?: number
  requestCollection?: {
    id: number
    items: Collection[]
  }
  questionAnswerClassificationRules?: Array<QuestionAnswerClassificationRule | undefined>
  fileScreeningPresetId?: number
  sections?: QuestionnaireTemplateSection[]
  tag?: string
  isStandard: boolean
}

export interface QuestionAnswerClassificationRule {
  ruleType: AssessmentRuleType
  value?: AnswerClassificationValue | string
  rank: QuestionAnswerRank
  questionId?: number
}

export interface QuestionCategorization {
  categoryName: string
  values: QuestionCategoryOption[]
}

export interface QuestionCategoryOption {
  id: number
  name: string
}

export enum QuestionAnswerRank {
  Neutral = 'Neutral',
  Positive = 'Positive',
  Negative = 'Negative',
  NegativeAdverse = 'NegativeAdverse',
  NotSet = 'NotSet',
}

export enum AnswerClassificationValue {
  Answered = 'Answered',
  NotApplicable = 'NotApplicable',
  NotAvailable = 'NotAvailable',
  Other = 'Other',
}

export interface AssessmentTemplate {
  id: number
  name: string
  createdAt: string
  description: string
  level1Threshold: number
  level2Threshold: number
  level3Threshold: number
  level4Threshold: number
  level1Description: string
  level2Description: string
  level3Description: string
  level4Description: string
  level5Description: string
  questionnaireTemplateId: number
  questionnaireTemplate: QuestionnaireTemplate
  sections: AssessmentSection[]
  levels?: number
  assessmentType: AssessmentType
  isPublished: boolean
  rules?: AssessmentRule[]
}

export interface AssessmentSection {
  id: number
  name: string
  description: string
  level1Threshold: number
  level2Threshold: number
  level3Threshold: number
  level4Threshold: number
  assessmentSectionNumber: number
  assessmentTemplateId: number
  assessmentTemplate?: AssessmentTemplate
  rules?: AssessmentRule[]
}

export interface AssessmentRule {
  id: number
  questionId: number
  question: Question
  assessmentTemplateId: number
  assessmentTemplate?: AssessmentTemplate
  assessmentSectionId: number
  value: string
  points: number
  isNeutral: boolean
  ruleType: AssessmentRuleType
}

export enum AssessmentRuleType {
  SelectedValue = 'SelectedValue',
  CannotAnswer = 'CannotAnswer',
  FileUploaded = 'FileUploaded',
  NumberEqualTo = 'NumberEqualTo',
  NumberGreaterThan = 'NumberGreaterThan',
  NumberLowerThan = 'NumberLowerThan',
  AnswerStatus = 'AnswerStatus',
}

export interface OrganizationAssessmentSettings {
  isActivated: boolean
  assessmentTemplate: AssessmentTemplate
}

export interface AssessmentQuestionnaireLink {
  id: number
  assessmentTemplateId: number
  questionnaireTemplateId: number
  assessmentTemplate?: AssessmentTemplate
  questionnaireTemplate?: QuestionnaireTemplate
}

export enum AssessmentType {
  BaselineAssessment = 'BaselineAssessment',
  StandardAssessment = 'StandardAssessment',
}

export enum SuggestedContactReasonType {
  NewCompanyContact = 'NewCompanyContact',
  NewAgentContact = 'NewAgentContact',
  NewConsultantContact = 'NewConsultantContact',
  ContactHasLeftTheCompany = 'ContactHasLeftTheCompany',
  ContactIsNotResponding = 'ContactIsNotResponding',
  Other = 'Other',
}

export enum SuggestedContactStatus {
  Requested = 'Requested',
  Disapproved = 'Disapproved',
  InvitationSent = 'InvitationSent',
  InvitationAccepted = 'InvitationAccepted',
}

export type SuggestedContact = {
  id: number
  createdAt: string
  creatorOrganizationId: number
  creatorOrganization?: GenericOrganization
  creatorUser?: User
  name: string
  email: string
  status: SuggestedContactStatus
  provider: Provider
  providerId: number
  reasonType: SuggestedContactReasonType
  reasonExtraInfo?: string
  organization?: GenericOrganization
}
